'use client';

import { CaseListItem, ICaseResponse } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import React from 'react';
import AnimatedTitle, { AnimationType } from '../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../components/Button';
import Img from '../../components/Img';
import { theme } from '../../store/theme';
import styles from './CaseCard.module.scss';

type CaseCardProps = {
  data: CaseListItem | ICaseResponse,
  searchView?: boolean,
};

const CaseCard = ({ data, searchView = false }: CaseCardProps) => {
  const appTheme = useAtomValue(theme);
  // temporary disabled feature
  // const image = data.previewImage || data.image;
  const { image } = data;
  const description = data.previewDescription || data.description;
  const title = data.previewTitle || data.title;

  return (
    <article className={classnames(styles.case, {
      [styles.case_searchView]: searchView,
    })}
    >
      {searchView ? (
        <div
          className={styles.image}
        >
          <Img
            src={image?.url || '/'}
            alt={image?.alternativeText || ''}
            withPreloader
          />
        </div>
      ) : (
        <Link
          href={`/cases/${data.slug}`}
          className={styles.image}
        >
          <Img
            src={image?.url || '/'}
            alt={image?.alternativeText || ''}
            withPreloader
          />
        </Link>
      )}
      <div className={styles.content}>
        <header>
          <h3 className={styles.projectName}>{data.projectName}</h3>
          <h2
            className={styles.title}
          >
            <AnimatedTitle
              animationType={AnimationType.NONE}
              title={title}
            />
          </h2>
        </header>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {!searchView && (
          <>
            <div className={styles.technologies}>
              <p className={styles.technologies__title}>Technologies</p>
              <div className={styles.technologies__content}>
                {data?.technologies?.map(technology => (
                  <div
                    className={styles.technology}
                    title={technology.name}
                    key={technology.id}
                  >
                    <img
                      loading='lazy'
                      src={technology.image?.[appTheme].url}
                      alt={technology.image?.[appTheme].alternativeText || 'technology image'}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Button
              link={`/cases/${data.slug}`}
              className={styles.viewButton}
              variant={ButtonVariants.TRANSPARENT}
              icon={(
                <svg>
                  <use
                    xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                    href='/media/arrow-right-bold.svg#arrowRight'
                  />
                </svg>
              )}
              iconPosition='right'
              iconSize={{ width: 24, height: 24 }}
            >
              View Project
            </Button>
          </>
        )}
      </div>
    </article>
  );
};

export default CaseCard;
